<template>
  <base-popup
    title="Выбор Документа"
    :icon="UtilGetIcon('Documents')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
  >
    <template v-slot:activator="{ on }">
      <m-combobox
        v-on="on"
        @click:append="on.click"
        :value="value"
        label="Выбор документа"
        :item-text="itemText"
        :disabled="disabled"
        required
        :rules="[(value) => !!value || 'Выберите документ']"
      ></m-combobox>
    </template>
    <v-container>
      <v-row>
        <v-col>
          <base-panel title="Типы документа">
            <base-search slot="action" v-model="search[1]" />
            <base-table
              v-model="selectTypes"
              :items="types"
              :search-text="search[1]"
              :headers="typeHeaders"
              hide-default-footer
              notShowSettings
              notFilter
            ></base-table>
          </base-panel>
        </v-col>
        <v-col>
          <base-panel title="Документ">
            <base-search slot="action" v-model="search[2]" />
            <base-table
              v-model="selectDocument"
              :search-text="search[2]"
              :items="filterDocuments"
              :headers="documentHeaders"
              hide-default-footer
              single-select
              notShowSettings
              notFilter
            >
              <template slot="item.Date" slot-scope="{ item }">
                {{ item.Date ? item.Date.substr(0, 10) : "" }}
              </template>
            </base-table>
          </base-panel>
        </v-col>
      </v-row>
    </v-container>
  </base-popup>
</template>

<script>
import LocalDocumentService from "../../services/LocalDocumentService";
import MCombobox from "../base/inputs/mCombobox.vue";
import BasePopup from "@/layouts/BasePopup.vue";
import { mapActions } from "vuex";
import BasePanel from "@/layouts/BasePanel.vue";
import BaseTable from "../base/BaseTable.vue";
import BaseSearch from "../base/BaseSearch.vue";

export default {
  components: { BasePopup, BasePanel, BaseTable, BaseSearch, MCombobox },
  props: {
    value: Object,
    width: String,
    height: String,
    onlyStatus: { type: Number, default: -1 },
    disabled: Boolean,
  },
  data() {
    return {
      isInit: false,
      search: ["", "", ""],
      types: [],
      documents: [],
      selectTypes: [],
      selectDocument: null,
      typeHeaders: [
        {
          text: "Тип",
          value: "Name",
        },
      ],
      documentHeaders: [
        {
          text: "Номер",
          value: "Num",
        },
        { text: "Дата", value: "Date", align: "center" },
        {
          text: "Название",
          value: "Name",
        },
      ],
      dialog: false,
    };
  },
  computed: {
    filterDocuments() {
      return this.documents.filter(
        (e) =>
          (this.onlyStatus > -1 ? e.Status === this.onlyStatus : true) &&
          (!e?.Type?.Id ||
            !this.selectTypes.length ||
            this.selectTypes.map((e2) => e2.Id).includes(e.Type.Id))
      );
    },
  },
  watch: {
    dialog(val) {
      if (val && !this.isInit) {
        this.init();
        this.isInit = true;
      }
    },
    value: {
      immediate: true,
      handler: function () {
        this.selectDocument = this.value;
      },
    },
  },

  methods: {
    ...mapActions("loaderDataSources", ["LOAD_ORGGANIZATION_DOCUMENT_TYPES"]),
    itemText(val) {
      const item = val.length ? val[0] : val;
      return "#" + item.Nomer + " " + item.Naimenovanie;
    },
    async init() {
      this.types = await this.LOAD_ORGGANIZATION_DOCUMENT_TYPES();
      const res = await LocalDocumentService.get();
      this.documents = res.data;
    },
    async save() {
      const document = this.selectDocument.length
        ? this.selectDocument[0]
        : this.selectDocument;
      try {
        const { data } = await LocalDocumentService.show(document.Id);
        this.$emit("input", data);
      } catch (error) {
        console.log(error);
      }

      this.dialog = false;
    },
  },
};
</script>

<style></style>
