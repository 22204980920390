var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-detail-view',{attrs:{"loading":_vm.loading,"title":'Учебный материал, ' + _vm.typeMaterials[_vm.dataSource.Type] + '',"isUnsaved":_vm.isUnsaved && _vm.valid,"showSave":!_vm.isEmployee},on:{"onSave":_vm.save,"onRefresh":_vm.init}},[(!_vm.simple)?_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","dark":"","color":"primary","title":"Закрыть"},on:{"click":_vm.close},slot:"action"},[_c('m-icon',{attrs:{"icon":"mdi-close","left":""}}),_vm._v(" Закрыть ")],1):_vm._e(),_c('v-form',{staticClass:"block__content",attrs:{"readonly":_vm.readOnly},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Название","maxlength":"200","placeholder":"Введите название","autofocus":"","readonly":_vm.isEmployee,"required":"","rules":[_vm.rules.required]},model:{value:(_vm.dataSource.Name),callback:function ($$v) {_vm.$set(_vm.dataSource, "Name", $$v)},expression:"dataSource.Name"}})],1),_c('v-col',[_c('v-text-field',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Описание","maxlength":"200","readonly":_vm.isEmployee},model:{value:(_vm.dataSource.Description),callback:function ($$v) {_vm.$set(_vm.dataSource, "Description", $$v)},expression:"dataSource.Description"}})],1)],1),(_vm.dataSource.Type === 1 && !_vm.isEmployee)?_c('input-load-file',{attrs:{"files":[_vm.dataSource.File],"accept":".doc, .docx","label":"Выберите  текстовый документ для загрузки (.doc, .docx)","prepend-icon":"","required":"","rules":[
          (value) =>
            !!value || 'Выберите текстовый документ формата .doc или .docx',
        ],"hideClose":true},on:{"input":function($event){_vm.dataSource.File = $event[0]},"change":_vm.changeFile}}):(_vm.dataSource.Type === 2 && !_vm.isEmployee)?_c('document-select',{attrs:{"value":_vm.dataSource.Document,"onlyStatus":5},on:{"input":_vm.changeDocument}}):(_vm.dataSource.Type === 3 && !_vm.isEmployee)?_c('v-text-field',{attrs:{"label":"Ссылка на видео (YouTube, VK, RuTube, Яндекс.Диск, Прямая ссылка)","maxlength":"200","required":"","rules":[_vm.rules.required, _vm.rules.youtube]},model:{value:(_vm.dataSource.VideoUrl),callback:function ($$v) {_vm.$set(_vm.dataSource, "VideoUrl", $$v)},expression:"dataSource.VideoUrl"}}):(_vm.dataSource.Type === 4 && !_vm.isEmployee)?_c('input-load-file',{attrs:{"files":[_vm.dataSource.File],"accept":".ppt, .pptx","label":"Выберите файл презентации для загрузки (.ppt, .pptx)","prepend-icon":"","required":"","rules":[
          (value) =>
            !!value || 'Выберите текстовый документ формата .ppt или .pptx',
        ],"hideClose":true},on:{"input":function($event){_vm.dataSource.File = $event[0]},"change":_vm.changeFile}}):_vm._e()],1)],1),_c('div',{staticClass:"mt-2"},[_c('v-container',[(_vm.isMaterialEmpty)?_c('div',{staticClass:"matarial-edit__material-empty"},[_c('m-icon',{attrs:{"icon":"mdi-alert-decagram","x-large":""}}),_vm._v(" "),_c('br'),_c('b',[_vm._v("Учебных материалов нет")])],1):(
          _vm.dataSource.File && (_vm.dataSource.Type === 1 || _vm.dataSource.Type === 4)
        )?_c('material-pdf',{attrs:{"externalId":_vm.dataSource.File.ExternalId}}):(_vm.dataSource.Type === 2)?_c('fileLibrary',{ref:"fileLibrary",attrs:{"files":_vm.dataSource.Document.Files,"isContentVisible":_vm.dataSource.Document.IsContentVisible,"content":_vm.dataSource.Document.Content,"isEnabledContent":false,"templateId":_vm.dataSource.Document.TemplateId,"id":_vm.dataSource.Document.Id,"headerHidden":"","showAdd":false,"init":_vm.init,"save":_vm.save},on:{"update:files":function($event){return _vm.$set(_vm.dataSource.Document, "Files", $event)},"update:isContentVisible":function($event){return _vm.$set(_vm.dataSource.Document, "IsContentVisible", $event)},"update:is-content-visible":function($event){return _vm.$set(_vm.dataSource.Document, "IsContentVisible", $event)},"update:content":function($event){return _vm.$set(_vm.dataSource.Document, "Content", $event)}}}):(_vm.dataSource.Type === 3)?_c('material-youtube',{attrs:{"url":_vm.dataSource.VideoUrl}}):_vm._e()],1)],1),(_vm.isEmployee && !this.dataSource.Date)?_c('div',{staticClass:"mt-2 px-5 block__content"},[_c('v-row',[_c('div',[_c('v-col',{staticClass:"matarial-edit__confirm-inner-checkbox"},[_c('v-checkbox',{staticClass:"matarial-edit__confirm-checkbox",staticStyle:{"margin-left":"22px"},attrs:{"label":"Я подтверждаю, что настоящий  учебный материал мною полностью изучен"},model:{value:(_vm.checkBox),callback:function ($$v) {_vm.checkBox=$$v},expression:"checkBox"}})],1)],1),_c('v-col',{staticClass:"matarial-edit__confirm-inner-btn"},[_c('v-btn',{attrs:{"color":"primary","dark":"","disabled":!_vm.checkBox},on:{"click":_vm.execute}},[_vm._v("Подтвердить")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }