<template>
  <div>
    <pdf-viewer
      v-if="externalId"
      :url="`${apiUrl}common/GetFileStream?externalId=` + `${externalId}`"
    ></pdf-viewer>
  </div>
</template>
<script>
import PdfViewer from "../../../../components/rich/PdfViewer.vue";
export default {
  name: "knowledgeBase-pdf",
  components: { PdfViewer },
  props: {
    externalId: {
      type: String,
      default: "",
    },
  },
  computed: {
    apiUrl() {
      // Полный путь к url должен быть именно в компоненте, а не в шаблоне.
      return process.env.VUE_APP_SERVER_URL;
    },
  },
  methods: {},
};
</script>
